<template>
    <div class="lvya-dataCenter">
        <div class="dataCenter-banner">
            <img src="static/lvya/basicPlatform/dataCenter/lunbo1.jpg" alt="" style="width:100%;height: 100%;">
            <!-- <div class="mtop">
                <div class="mtop-left">
                    <div>
                        <img src="static/lvya/basicPlatform/dataCenter/rj.png" alt="">
                        <p class="mtop-left-p">软件支撑</p>
                    </div>
                    <p class="mtop-left-text">一卡通、校园网广播系统、录播系统等等。</p>
                </div>
                <div class="mtop-center">
                    <img src="static/lvya/basicPlatform/dataCenter/rjyjbg.png" alt="" style="height:240px;">
                </div>
                <div class="mtop-right">
                    <div>
                        <img src="static/lvya/basicPlatform/dataCenter/rj.png" alt="">
                        <p class="mtop-right-p">硬件支撑</p>
                    </div>
                    <p class="mtop-right-text">资产管理学籍管理、档案管理等等。</p>
                </div>
            </div> -->
        </div>
        <div class="bg-box">
            <div class="box-div1">
                <p class="administration-tit">开放式智慧校园</p>
                <p class="box-div1-text">
                    以学生成长为核心，通过核心技术构建的过程管理引擎。
                    各种应用，随需而生，可以把学校任何一个教育过程或者实际场景，
                    及时形成一套系统。同时，系统具有完全开放的动态接口，
                    可以随时接入软硬件环境来支撑学校的教育过程和教育场景的生成，
                    让学校所有软硬件都体现出育人功能。
                </p>
            </div>
        </div>
        <div class="box-div2">
            <ul class="box-div2-ul">
                <li class="wow animate__zoomIn" data-wow-duration ="1.5s" data-wow-delay ="0s">
                    <img src="static/lvya/basicPlatform/dataCenter/yszs.png" alt="">
                    <p class="box-div2-ul-li-p1">样式展示</p>
                    <p class="box-div2-ul-li-p2">图文记录、手机端样式、pc端样式。</p>
                </li>
                <li class="wow animate__zoomIn" data-wow-duration ="1.5s" data-wow-delay ="0s">
                    <img src="static/lvya/basicPlatform/dataCenter/lcgl.png" alt="">
                    <p class="box-div2-ul-li-p1">流程管理</p>
                    <p class="box-div2-ul-li-p2">审批流程、审批方式数据流、控制流</p>
                </li>
                <li class="wow animate__zoomIn" data-wow-duration ="1.5s" data-wow-delay ="0s">
                    <img src="static/lvya/basicPlatform/dataCenter/zxdx.png" alt="">
                    <p class="box-div2-ul-li-p1">执行对象</p>
                    <p class="box-div2-ul-li-p2">老师、学生、后勤校领导、教研组</p>
                </li>
                <li class="wow animate__zoomIn" data-wow-duration ="1.5s" data-wow-delay ="0s">
                    <img src="static/lvya/basicPlatform/dataCenter/sjjl.png" alt="">
                    <p class="box-div2-ul-li-p1">数据记录</p>
                    <p class="box-div2-ul-li-p2">时间、数据、文本</p>
                </li>
                <li class="wow animate__zoomIn" data-wow-duration ="1.5s" data-wow-delay ="0s">
                    <img src="static/lvya/basicPlatform/dataCenter/zxcz.png" alt="">
                    <p class="box-div2-ul-li-p1">执行动作</p>
                    <p class="box-div2-ul-li-p2">推送、通知、填报、审核、查询</p>
                </li>
            </ul>
        </div>
        <div class="bg-box2">
            <div class="box-div3">
                <p class="administration-tit">数据中台</p>
                <p class="box-div1-text">
                    解决学校应用系统之间“各自为政、条块分割”的现状，
                    提高应用系统和业务数据的利用率，使得信息化工具能真正的和学校的教育教学活动相结合，
                    规范学校管理、提升教学质量、促进学院快速、持续、健康稳定的发展。
                </p>
                <img src="static/lvya/basicPlatform/dataCenter/sjzx.png" alt="" style="width:100%;">
            </div>
        </div>
        <div class="bg-box3">
            <div class="bg-box3-left">
                <ul>
                    <li class="wow animate__fadeInLeftBig" data-wow-duration ="3.7s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/dataCenter/jwxt.png" alt="">
                        <p>教务系统</p>
                    </li>
                    <li class="wow animate__fadeInLeftBig" data-wow-duration ="3.5s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/dataCenter/jkyxt.png" alt="">
                        <p>教科研系统</p>
                    </li>
                    <li class="wow animate__fadeInLeftBig" data-wow-duration ="3s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/dataCenter/jxxt.png" alt="">
                        <p>教学系统</p>
                    </li>
                    <li class="wow animate__fadeInLeftBig jt" data-wow-duration ="2.5s" data-wow-delay ="0s">
                        <p>软件</p>
                    </li>
                </ul>
                <ul>
                    <li class="wow animate__fadeInLeftBig" data-wow-duration ="3.7s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/dataCenter/mjxt.png" alt="">
                        <p>门禁系统</p>
                    </li>
                    <li class="wow animate__fadeInLeftBig" data-wow-duration ="3.5s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/dataCenter/yktxt.png" alt="">
                        <p>一卡通系统</p>
                    </li>
                    <li class="wow animate__fadeInLeftBig" data-wow-duration ="3s" data-wow-delay ="0s">
                        <img src="static/lvya/basicPlatform/dataCenter/dztsgxt.png" alt="">
                        <p>电子图书馆系统</p>
                    </li>
                    <li class="wow animate__fadeInLeftBig jt" data-wow-duration ="2.5s" data-wow-delay ="0s">
                        <p>硬件</p>
                    </li>
                </ul>
            </div>
            <div class="wow animate__fadeInLeftBig bg-box3-right" data-wow-duration ="2.5s" data-wow-delay ="0s">
                <img src="static/lvya/basicPlatform/dataCenter/ryjzdjr.png" alt="" style="width:100%;">
            </div>
        </div>
        <div class="bg-box4-div">
            <div class="bg-box4">
                <div class="bg-box4-left" style="margin-left:80px;">
                    <p class="bg-box4-left-p1">数据交互</p>
                    <p class="bg-box4-left-p2">
                        实现与学籍管理系统、教育资源库、及其他智慧校园应用系统之间数据的交换、
                        共享共通。为智慧教育实现各种数据挖掘分析、为教育决策提供数据支撑。
                    </p>
                </div>
                <div class="bg-box4-right">
                    <img src="static/lvya/basicPlatform/dataCenter/sjjh.gif" alt="" style="width:100%;">
                </div>
            </div>
        </div>
        <div class="bg-box4-div" style="background:#fff;">
            <div class="bg-box4">
                <div class="bg-box4-right">
                    <img src="static/lvya/basicPlatform/dataCenter/sjqx.gif" alt="" style="width:100%;">
                </div>
                <div class="bg-box4-left" style="margin-right:80px;">
                    <p class="bg-box4-left-p1">数据清洗</p>
                    <p class="bg-box4-left-p2">
                        解决学校“数据丰富，信息贫乏”，的问题，检测数据中存在的错误，
                        剔除改正学校“垃圾”数据，确保高质量数据。
                    </p>
                </div>
            </div>
        </div>
        <div class="bg-box4-div">
            <div class="bg-box4">
                <div class="bg-box4-left" style="margin-left:80px;">
                    <p class="bg-box4-left-p1">数据聚合</p>
                    <p class="bg-box4-left-p2">
                        调取标准数据并进行筛选与处理。提取出需要的信息部分，
                        生成符合个性化需要的数据统计分析报表。
                    </p>
                </div>
                <div class="bg-box4-right">
                    <img src="static/lvya/basicPlatform/dataCenter/sjjh2.gif" alt="" style="width:100%;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: '0',
                mobile: true,
                live: true
            })
            wow.init()
        })
    },
}
</script>

<style>
/* @import url("../style/style.less"); */
.dataCenter-banner{
    position: relative;
    display: flex;
    justify-content: center;
}
.bg-box{
    width: 100%;
    background: url('../../../../public/static/lvya/basicPlatform/dataCenter/bg1.png') no-repeat;
    background-size: 100% 100%;
}
.box-div1{
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 50px 0px;
    box-sizing: border-box;
}
.box-div1-text{
    color: #666;
    line-height: 30px;
    margin: 20px 0;
    text-align: center;
}
.box-div2{
    width: 1200px;
    min-height: 200px;
    margin: 0 auto;
}
.box-div2-ul{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.box-div2-ul li{
    width: 18%;
    text-align: center;
}
.box-div2-ul li img{
    width: 180px;
}
.box-div2-ul-li-p1{
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin: 10px 0;
}
.box-div2-ul-li-p2{
    font-size: 16px;
    color: #666;
}
.box-div3{
    width: 1200px;
    min-height: 200px;
    margin: 50px auto;
    padding: 50px 0;
}
.bg-box2{
    width: 100%;
    background: url('../../../../public/static/lvya/basicPlatform/dataCenter/bg1.png') no-repeat;
    background-size: 100% 100%;
}
.bg-box3{
    width: 1200px;
    min-height: 300px;
    margin: 100px auto;
    background: #fff;
    display: flex;
    justify-content: space-between;
}
.bg-box3-left{
    width: 800px;
    background: #fff;
}
.bg-box3-right{
    width: 400px;
    background: #fff;
}
.bg-box3-left ul{
    display: flex;
    justify-content: space-around;
}
.bg-box3-left ul li{
    width: 24%;
    text-align: center;
    -moz-box-shadow:5px 5px 13px #e0f8ee; -webkit-box-shadow:5px 5px 13px #e0f8ee; box-shadow: 5px 5px 13px #e0f8ee;
    padding: 20px 0;
}
.bg-box3-left ul li p{
    color: #19ca81;
    line-height: 25px;
}
.bg-box3-left ul .jt{
    width: 140px;
    height: 90px;
    margin-top: 20px;
    background: url('../../../../public/static/lvya/basicPlatform/dataCenter/jt.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    box-shadow: none;
}
.bg-box3-left ul .jt p{
    font-size: 20px;
    color: #19ca81;
    padding-left: 30px;
    font-weight: 600;
}
.bg-box4-div{
    background: #fafafa;
}
.bg-box4{
    width: 1200px;
    min-height: 300px;
    margin: 50px auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
}
.bg-box4-left{
    width: 360px;
    height: 320px;
    background: url('../../../../public/static/lvya/basicPlatform/dataCenter/gifbg.png') no-repeat;
    background-size: 100% 100%;
    padding: 40px 80px;
    margin-top: 100px;
}
.bg-box4-left-p1{
    font-size: 24px;
    color: #333;
    text-align: center;
}
.bg-box4-left-p2{
    line-height: 30px;
    color: #666;
    margin-top: 10px;
}
.bg-box4-right{
    width: 700px;
}
.administration-tit{
    text-align: center;
    font-size: 24px;
    color: #333;
    font-weight: 600;
}
</style>
